import { useState } from 'react';
import { Search } from 'lucide-react';
import { TextField, InputAdornment, Box } from '@mui/material';

interface AddressSearchProps {
  onSearch: (address: string) => void;
}

const AddressSearch = ({ onSearch }: AddressSearchProps) => {
  const [address, setAddress] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (address.trim()) {
      onSearch(address);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Search Address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search size={20} />
            </InputAdornment>
          ),
        }}
        placeholder="Enter an address to center the map"
      />
    </Box>
  );
};

export default AddressSearch;