import { useState, useCallback } from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import Map from '@/components/Map';
import AddressSearch from '@/components/AddressSearch';
import SearchForm from '@/components/SearchForm';
import { useGoogleServices } from '@/hooks/useGoogleServices';
import { downloadCSV } from '@/utils/csv';
import type { PlaceResult, LatLngLiteral } from '@/types';

interface DealershipInfo {
    shortName: string;
    officialName: string;
}

function App() {
    const [searchLocation, setSearchLocation] = useState<LatLngLiteral | null>(null);
    const [radius, setRadius] = useState<number>(5);
    const [places, setPlaces] = useState<PlaceResult[]>([]);
    const { loading, error, searchPlaces, geocodeAddress } = useGoogleServices();
    const [, setDealershipInfo] = useState<DealershipInfo | null>(null);

    const handlePinDrop = useCallback((location: LatLngLiteral) => {
        setSearchLocation(location);
    }, []);

    const handleAddressSearch = async (address: string) => {
        try {
            const location = await geocodeAddress(address);
            setSearchLocation(location);
        } catch (error) {
            console.error('Address search failed:', error);
        }
    };

    const handlePlacesSearch = async (searchRadius: number, categories: string[], dealershipInfo: DealershipInfo) => {
        if (!searchLocation) return;

        setDealershipInfo(dealershipInfo);

        try {
            const results = await searchPlaces(searchLocation, searchRadius, categories);
            setPlaces(results);
        } catch (error) {
            console.error('Places search failed:', error);
            setPlaces([]);
        }
    };

    const handleDownload = (dealershipInfo: DealershipInfo) => {
        downloadCSV(places, dealershipInfo);
    };

    return (
        <Box sx={{ py: 4 }}>
            <Container maxWidth="lg">
                <Paper elevation={3}>
                    <Box sx={{ p: 2 }}>
                        <AddressSearch onSearch={handleAddressSearch} />
                        <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block', textAlign: 'center' }}>
                            Search for an address to center the map or click anywhere on the map to drop a pin
                        </Typography>
                    </Box>

                    <Map
                        center={searchLocation || { lat: 40.7128, lng: -74.0060 }}
                        radius={Math.max(1, radius)}
                        places={places}
                        searchLocation={searchLocation}
                        onPinDrop={handlePinDrop}
                    />

                    <Box sx={{ p: 2 }}>
                        <SearchForm
                            onSearch={handlePlacesSearch}
                            onRadiusChange={setRadius}
                            initialRadius={Math.max(1, radius)}
                            loading={loading}
                            disabled={!searchLocation}
                            places={places}
                            onDownload={handleDownload}
                        />

                        {error && (
                            <Typography color="error" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}

export default App;
