import { LatLngLiteral } from '../types';

export class GeocodingService {
  private geocoder: google.maps.Geocoder;

  constructor() {
    this.geocoder = new google.maps.Geocoder();
  }

  async geocodeAddress(address: string): Promise<LatLngLiteral> {
    try {
      const result = await this.geocoder.geocode({ address });
      
      if (!result.results[0]) {
        throw new Error('Location not found');
      }

      const location = result.results[0].geometry.location;
      return {
        lat: location.lat(),
        lng: location.lng()
      };
    } catch (error) {
      console.error('Geocoding failed:', error);
      throw new Error('Failed to find location');
    }
  }
}