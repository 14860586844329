import { useEffect, useRef, useState } from 'react';
import { Box, Alert } from '@mui/material';
import { useGoogleMaps } from '../hooks/useGoogleMaps';
import type { PlaceResult, LatLngLiteral } from '@/types';

interface MapProps {
  center: LatLngLiteral;
  radius: number;
  places?: PlaceResult[];
  searchLocation: LatLngLiteral | null;
  onPinDrop?: (location: LatLngLiteral) => void;
}

const Map = ({ center, radius, searchLocation, onPinDrop }: MapProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const { isLoaded, error } = useGoogleMaps();

  useEffect(() => {
    if (!isLoaded || !mapRef.current) return;

    const newMap = new google.maps.Map(mapRef.current, {
      center,
      zoom: 12,
      mapTypeControl: false,
    });

    const newCircle = new google.maps.Circle({
      map: newMap,
      center,
      radius: radius * 1609.34, // Convert miles to meters
      fillColor: '#FF0000',
      fillOpacity: 0.1,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    });

    const newMarker = new google.maps.Marker({
      position: center,
      map: newMap,
      draggable: false,
    });

    newMap.addListener('click', (e: google.maps.MapMouseEvent) => {
      if (!e.latLng) return;
      const clickedLocation = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      newMarker.setPosition(clickedLocation);
      newCircle.setCenter(clickedLocation);
      onPinDrop?.(clickedLocation);
    });

    setMap(newMap);
    setCircle(newCircle);
    setMarker(newMarker);

    return () => {
      newCircle.setMap(null);
      newMarker.setMap(null);
    };
  }, [isLoaded, center]);

  useEffect(() => {
    if (!circle || !map || !searchLocation) return;

    circle.setRadius(radius * 1609.34);
    circle.setCenter(searchLocation);

    const bounds = circle.getBounds();
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [radius, circle, map, searchLocation]);

  useEffect(() => {
    if (!marker || !searchLocation) return;
    marker.setPosition(searchLocation);
  }, [marker, searchLocation]);

  if (error) {
    return (
      <Alert severity="error">
        Failed to load Google Maps: {error.message}
      </Alert>
    );
  }

  return (
    <Box
      ref={mapRef}
      sx={{
        width: '100%',
        height: '500px',
        borderRadius: 1,
      }}
    />
  );
};

export default Map;
