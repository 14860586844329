import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Slider,
  Typography,
  Chip,
  TextField,
  Paper,
  InputAdornment,
  CircularProgress,
  Alert,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Link,
  TextareaAutosize
} from '@mui/material';
import { Search, Download, ChevronDown, ChevronUp, Check } from 'lucide-react';
import categories from '@/data/categories.json' assert { type: 'json' };

const allCategories = categories;

interface SearchFormProps {
  onSearch: (radius: number, categories: string[], dealershipInfo: DealershipInfo) => void;
  onRadiusChange: (radius: number) => void;
  initialRadius: number;
  loading?: boolean;
  disabled?: boolean;
  places: any[];
  onDownload: (dealershipInfo: DealershipInfo) => void;
}

interface DealershipInfo {
  shortName: string;
  officialName: string;
}

const SearchForm = ({
  onSearch,
  onRadiusChange,
  initialRadius,
  loading = false,
  disabled = false,
  places = [],
  onDownload
}: SearchFormProps) => {
  const [radius, setRadius] = useState(Math.max(1, initialRadius));
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [dealershipShortName, setDealershipShortName] = useState('');
  const [dealershipOfficialName, setDealershipOfficialName] = useState('');
  const [showBulkInput, setShowBulkInput] = useState(false);
  const [bulkCategories, setBulkCategories] = useState('');

  useEffect(() => {
    setRadius(Math.max(1, initialRadius));
  }, [initialRadius]);

  const processBulkCategories = () => {
    // Split by either newlines or commas
    const inputCategories = bulkCategories
      .split(/[\n,]/)
      .map(cat => cat.trim().toLowerCase())
      .filter(cat => cat.length > 0);

    // Find exact matching categories and subcategories
    const newSelectedCategories = new Set(selectedCategories);

    allCategories.categories.forEach(category => {
      category.subcategories.forEach(sub => {
        const subLabel = sub.label.toLowerCase();
        const subId = sub.id.toLowerCase().replace(/_/g, ' ');

        if (inputCategories.some(input =>
          input === subLabel ||
          input === subId
        )) {
          newSelectedCategories.add(sub.id);
        }
      });
    });

    setSelectedCategories(Array.from(newSelectedCategories));
    setBulkCategories(''); // Clear the input after processing
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchPerformed(true);
    onSearch(radius, selectedCategories, {
      shortName: dealershipShortName,
      officialName: dealershipOfficialName
    });
  };

  const handleRadiusChange = (_: Event, value: number | number[]) => {
    const newRadius = value as number;
    setRadius(newRadius);
    onRadiusChange(newRadius);
  };

  const toggleExpandCategory = (categoryId: string) => {
    setExpandedCategories(prev =>
      prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const toggleCategory = (categoryId: string, isParent = false) => {
    const category = allCategories.categories.find(cat => cat.id === categoryId);

    if (isParent && category) {
      // If it's a parent category, toggle all its subcategories
      const subcategoryIds = category.subcategories.map(sub => sub.id);
      setSelectedCategories(prev => {
        const hasAllSubcategories = subcategoryIds.every(id => prev.includes(id));
        if (hasAllSubcategories) {
          // If all subcategories are selected, remove them all
          return prev.filter(id => !subcategoryIds.includes(id));
        } else {
          // Otherwise, add all missing subcategories
          const currentSet = new Set(prev);
          subcategoryIds.forEach(id => currentSet.add(id));
          return Array.from(currentSet);
        }
      });
    } else {
      // Toggle individual category
      setSelectedCategories(prev =>
        prev.includes(categoryId)
          ? prev.filter(id => id !== categoryId)
          : [...prev, categoryId]
      );
    }
  };

  const isParentCategorySelected = (category: typeof allCategories.categories[0]) => {
    return category.subcategories.every(sub =>
      selectedCategories.includes(sub.id)
    );
  };

  const isSomeSubcategoriesSelected = (category: typeof allCategories.categories[0]) => {
    return category.subcategories.some(sub =>
      selectedCategories.includes(sub.id)
    );
  };

  const matchesSearch = (text: string) => {
    return text.toLowerCase().includes(searchTerm.toLowerCase());
  };

  const filteredCategories = allCategories.categories.filter(category =>
    matchesSearch(category.label) ||
    category.subcategories.some(sub => matchesSearch(sub.label))
  );

  const handleDownload = () => {
    onDownload({
      shortName: dealershipShortName,
      officialName: dealershipOfficialName
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Search categories..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Link
            component="button"
            type="button"
            onClick={() => setShowBulkInput(!showBulkInput)}
            sx={{ mb: 2, display: 'block' }}
          >
            {showBulkInput ? 'Hide Bulk Category Input' : 'Show Bulk Category Input'}
          </Link>

          <Collapse in={showBulkInput}>
            <Box sx={{ mb: 2 }}>
              <TextareaAutosize
                minRows={3}
                placeholder="Paste categories (comma or newline separated)"
                value={bulkCategories}
                onChange={(e) => setBulkCategories(e.target.value)}
                style={{
                  width: '100%',
                  padding: '8px',
                  marginBottom: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
              <Button
                variant="outlined"
                onClick={processBulkCategories}
                disabled={!bulkCategories.trim()}
                size="small"
              >
                Process Categories
              </Button>
            </Box>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              bgcolor: 'background.default',
              maxHeight: '400px',
              overflowY: 'auto'
            }}
          >
            <List dense>
              {filteredCategories.map((category) => (
                <Box key={category.id}>
                  <ListItem
                    dense
                    secondaryAction={
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => toggleExpandCategory(category.id)}
                      >
                        {expandedCategories.includes(category.id) ?
                          <ChevronUp size={20} /> :
                          <ChevronDown size={20} />
                        }
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <Chip
                        size="small"
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {isParentCategorySelected(category) && <Check size={16} />}
                            {category.label}
                          </Box>
                        }
                        onClick={() => toggleCategory(category.id, true)}
                        color={isParentCategorySelected(category) ? "primary" : "default"}
                        variant={isSomeSubcategoriesSelected(category) ? "outlined" : "filled"}
                        sx={{ minWidth: '200px' }}
                      />
                    </ListItemIcon>
                  </ListItem>
                  <Collapse in={expandedCategories.includes(category.id)}>
                    <Box
                      sx={{
                        pl: 4,
                        pr: 2,
                        py: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1
                      }}
                    >
                      {category.subcategories
                        .filter(sub => matchesSearch(sub.label))
                        .map((subcategory) => (
                          <Chip
                            key={subcategory.id}
                            size="small"
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                {selectedCategories.includes(subcategory.id) && <Check size={16} />}
                                {subcategory.label}
                              </Box>
                            }
                            onClick={() => toggleCategory(subcategory.id)}
                            color={selectedCategories.includes(subcategory.id) ? "primary" : "default"}
                            sx={{
                              minWidth: 'auto',
                              flex: '1 1 auto',
                              maxWidth: 'calc(33.333% - 8px)',
                              justifyContent: 'flex-start'
                            }}
                          />
                        ))}
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ px: 2 }}>
            <Typography gutterBottom>
              Search Radius: {radius} miles
            </Typography>
            <Slider
              value={radius}
              onChange={handleRadiusChange}
              valueLabelDisplay="auto"
              min={1}
              max={100}
              marks={[
                { value: 1, label: '1mi' },
                { value: 25, label: '25mi' },
                { value: 50, label: '50mi' },
                { value: 75, label: '75mi' },
                { value: 100, label: '100mi' },
              ]}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Dealership Short Name"
            placeholder="Short Name or Acronym"
            value={dealershipShortName}
            onChange={(e) => setDealershipShortName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Dealership Official Name"
            placeholder="Official Name"
            value={dealershipOfficialName}
            onChange={(e) => setDealershipOfficialName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={places.length > 0 ? 8 : 12}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                disabled={disabled || loading}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
              >
                {loading ? 'Searching...' : 'Search Places'}
              </Button>
            </Grid>
            {places.length > 0 && (
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={handleDownload}
                  startIcon={<Download size={20} />}
                  disabled={loading}
                >
                  Download
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {searchPerformed && !loading && places.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              No places found for the selected criteria. Try adjusting your search radius or selecting different categories.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SearchForm;
