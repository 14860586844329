import type {Libraries} from "@googlemaps/js-api-loader";

interface MapsConfig {
  apiKey: string;
  libraries:  Libraries;
}

export const mapsConfig: MapsConfig = {
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '',
  libraries: ['places', 'geometry']
};

export const validateMapsConfig = (): void => {
  if (!mapsConfig.apiKey) {
    throw new Error(
      'Google Maps API key is missing. Please add your API key to the .env file as VITE_GOOGLE_MAPS_API_KEY.'
    );
  }
};
