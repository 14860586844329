import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { mapsConfig, validateMapsConfig } from '../config/maps';

interface UseGoogleMapsReturn {
  isLoaded: boolean;
  error: Error | null;
}

export const useGoogleMaps = (): UseGoogleMapsReturn => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      validateMapsConfig();
      
      const loader = new Loader({
        apiKey: mapsConfig.apiKey,
        version: 'weekly',
        libraries: mapsConfig.libraries,
      });

      loader
        .load()
        .then(() => {
          setIsLoaded(true);
        })
        .catch((err) => {
          setError(err);
        });
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to load Google Maps'));
    }
  }, []);

  return { isLoaded, error };
};